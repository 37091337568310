import { createFeatureSelector, createSelector } from '@ngrx/store';
import { merge } from 'lodash';

import {
  TableauMapping,
  TableauMappingAccommodationDetails,
  TableauMappingAccommodationProperty,
  TableauMappingAccommodationTableauNumbers,
} from '../../models';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectTableauNumberState =
  createFeatureSelector<State>('tableauNumbers');

export const selectTableauNumbersError = createSelector(
  selectTableauNumberState,
  getError,
);

export const selectTableauNumbersIsLoading = createSelector(
  selectTableauNumberState,
  getIsLoading,
);

export const selectTableauNumbersData = createSelector(
  selectTableauNumberState,
  (state) => getData(state.mapping),
);

export const selectTableauNumbersAccommodationId = createSelector(
  selectTableauNumberState,
  (state) => getDataTableauNumbersAccommodationId(state.mapping),
);

export const selectMapping = createSelector(
  selectTableauNumberState,
  (state: State) => state.mapping,
);

export const selectTableauNumbers = createSelector(
  selectTableauNumberState,
  (state: State) => getNumbers(state.mapping),
);

const getData = (
  mapping: TableauMapping,
): { [accommodationId: string]: TableauMappingAccommodationTableauNumbers[] } =>
  Object.values(mapping || {}).reduce(
    (accommodations, propertyAccommodations) => {
      propertyAccommodations.forEach((accommodation) => {
        const { accommodation_details, tableau_numbers, property_details } =
          accommodation;
        const { id } = accommodation_details;

        accommodations = {
          ...accommodations,
          [id]: tableau_numbers.map((tableau_number) => {
            return {
              ...tableau_number,
              accommodation_id: id,
              property_id: property_details?.property_id,
            };
          }),
        };
      });

      return accommodations;
    },
    {},
  );

const getDataTableauNumbersAccommodationId = (
  mapping: TableauMapping,
): { [accommodationId: string]: TableauMappingAccommodationTableauNumbers[] } =>
  Object.values(mapping || {}).reduce(
    (tableauNumbers, propertyAccommodations) => {
      propertyAccommodations.forEach((accommodation) => {
        const { accommodation_details, tableau_numbers } = accommodation;
        const { id: accommodationId } = accommodation_details;

        tableau_numbers?.map((tableau) => {
          tableauNumbers = { ...tableauNumbers, [tableau.id]: accommodationId };
        });
      });

      return tableauNumbers;
    },
    {},
  );

const getNumbers = (
  mapping: TableauMapping,
): {
  [propertyId: string]: {
    [accommodationId: string]: {
      name: string;
      accommodationId: string;
      tableau: {
        [tableauNumberId: string]: {
          accommodation_details: TableauMappingAccommodationDetails;
          property_details: TableauMappingAccommodationProperty;
        } & TableauMappingAccommodationTableauNumbers;
      };
    };
  };
} => {
  return Object.entries(mapping || {}).reduce(
    (numbers, [propertyId, propertyAccommodations]) => {
      propertyAccommodations.forEach((accommodation) => {
        const { accommodation_details, property_details, tableau_numbers } =
          accommodation;

        tableau_numbers?.forEach((tableauNumber) => {
          numbers = merge({}, numbers, {
            [propertyId]: {
              [accommodation_details.id]: {
                accommodationId: accommodation_details.id,
                name: accommodation_details.name,
                is_virtual: !!accommodation_details.is_virtual,
                tableau: {
                  [tableauNumber.id]: {
                    ...tableauNumber,
                    property_details,
                    accommodation_details,
                  },
                },
              },
            },
          });
        });
      });

      return numbers;
    },
    {},
  );
};

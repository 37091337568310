import { TableauMapping } from '../../models/objects/tableau-mapping';

export interface State {
  mapping: TableauMapping;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  mapping: null,
  isLoading: false,
  error: null,
};

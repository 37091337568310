import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TableauNumbersService } from '../../services';

import * as featureActions from './actions';

@Injectable()
export class TableauNumbersStoreEffects {
  constructor(
    private dataService: TableauNumbersService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
    private notifications: NotificationService,
  ) {}

  loadRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap(({ payload }: featureActions.LoadRequestAction) =>
        this.dataService.load(payload.propertyId, payload.referenceDate).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  updateLabelRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateDetailsRequestAction>(
        featureActions.ActionTypes.UPDATE_LABEL_REQUEST,
      ),
      switchMap((action: featureActions.UpdateDetailsRequestAction) => {
        return this.dataService.updateTableauDetails(action.payload).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('tableau_number'),
              }),
              type: 'success',
            });
            return new featureActions.UpdateDetailsSuccessAction(
              action.payload,
            );
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.UpdateDetailsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  orderRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.OrderRequestAction>(
        featureActions.ActionTypes.ORDER_REQUEST,
      ),
      switchMap((action: featureActions.OrderRequestAction) => {
        const { tableauNumbersId, accommodationId } = action.payload;
        return this.dataService.order(accommodationId, tableauNumbersId).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('tableau_number'),
              }),
              type: 'success',
            });
            return new featureActions.OrderSuccessAction(action.payload);
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.OrderFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}

import { merge } from 'lodash';
import { TableauMappingAccommodationTableauNumbers } from '../../models';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;
      return {
        ...state,
        isLoading: false,
        mapping: items,
      };
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.UPDATE_LABEL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.UPDATE_LABEL_SUCCESS: {
      const { propertyId, commonId, accommodation_tableau_number_id } =
        action.payload;

      const mapping = {
        ...state.mapping,
        [propertyId]: state.mapping[propertyId].map((accommodation) => {
          return {
            ...accommodation,
            tableau_numbers: accommodation.tableau_numbers.map(
              (tableauNumber) => {
                if (tableauNumber.common_id !== commonId) {
                  return tableauNumber;
                }

                const { accommodation_details } = accommodation;
                const { label, door_key_codes } = action.payload;

                let changes: Partial<TableauMappingAccommodationTableauNumbers> =
                  {};

                // Se è il tableauNumber che ho modificato direttamente aggiungo la label ai changes
                if (tableauNumber.id === accommodation_tableau_number_id) {
                  changes = { ...changes, label, door_key_codes };
                }

                // La label deve essere modificata anche a tutti i tableauNumbers con lo stesso commonId,
                // ma solo se non si tratta di un collegamento somma.
                if (
                  !accommodation_details.is_sum &&
                  !accommodation_details.is_sum_parent
                ) {
                  changes = { ...changes, label, door_key_codes };
                }

                return {
                  ...tableauNumber,
                  ...changes,
                };
              },
            ),
          };
        }),
      };

      return {
        ...state,
        mapping,
        isLoading: false,
      };
    }

    case ActionTypes.UPDATE_LABEL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.ORDER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.ORDER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}

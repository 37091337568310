import { Action } from '@ngrx/store';

import { UpdateTableauDetailsRequest } from '../../features/commons/accommodations/models/accommodation.model';

export enum ActionTypes {
  LOAD_REQUEST = '[TableauNumbers] Load Request',
  LOAD_FAILURE = '[TableauNumbers] Load Failure',
  LOAD_SUCCESS = '[TableauNumbers] Load Success',

  ORDER_REQUEST = '[TableauNumbers] ORDER Request',
  ORDER_FAILURE = '[TableauNumbers] ORDER Failure',
  ORDER_SUCCESS = '[TableauNumbers] ORDER Success',

  UPDATE_LABEL_REQUEST = '[TableauNumbers] Update Label Request',
  UPDATE_LABEL_FAILURE = '[TableauNumbers] Update Label Failure',
  UPDATE_LABEL_SUCCESS = '[TableauNumbers] Update Label Success',

  RESET_STATE = '[TableauNumbers] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload: { propertyId: number[]; referenceDate?: string },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: any }) {}
}

export class UpdateDetailsRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_LABEL_REQUEST;
  constructor(
    public payload: UpdateTableauDetailsRequest & { propertyId: number },
  ) {}
}

export class UpdateDetailsFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_LABEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateDetailsSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_LABEL_SUCCESS;
  constructor(
    public payload: UpdateTableauDetailsRequest & { propertyId: number },
  ) {}
}
export class OrderRequestAction implements Action {
  readonly type = ActionTypes.ORDER_REQUEST;
  constructor(
    public payload: {
      accommodationId: number;
      tableauNumbersId: number[];
    },
  ) {}
}

export class OrderFailureAction implements Action {
  readonly type = ActionTypes.ORDER_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class OrderSuccessAction implements Action {
  readonly type = ActionTypes.ORDER_SUCCESS;
  constructor(
    public payload: {
      accommodationId: number;
      tableauNumbersId: number[];
    },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | UpdateDetailsRequestAction
  | UpdateDetailsFailureAction
  | UpdateDetailsSuccessAction
  | OrderRequestAction
  | OrderFailureAction
  | OrderSuccessAction
  | ResetSuccessAction;
